import "../scss/app.scss";
import Vue from "vue";
import "bootstrap";
// const carousels = require("./carousels");
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const app = new Vue({
  el: "#app",
  data: {
    isOpened: false,
    isToggleMenu: false,
  },
  mounted() {
    // carousels.carouselSingle();
    // carousels.carouselSingleThumbnail();
    // carousels.carouselCentering();
    // carousels.carouselSideScrolling();
    // carousels.carouselCover();
    this.InView();
    this.opening();
    // this.hero();
  },
  methods: {
    opening() {
      const tl = gsap.timeline({
        repeat: 0,
        defaults: {
          delay: 0,
          duration: 0.7,
          ease: "power2.out",
        },
      });
      tl.set(".opening", {
        scale: 1.2,
      })
        .set("#header.top", {
          opacity: 0,
          y: "-100%",
        })
        .to(".heroSM", {
          opacity: 1,
          duration: 2,
        })
        .to(".openingAnime", {
          opacity: 0.6,
          duration: 0.15,
        })
        .to(".openingAnime", {
          // delay: 0.1,
          scale: 0,
          opacity: 0,
          duration: 0.3,
        })
        .to(".openingAnime", {
          scale: 1,
          opacity: 1,
          duration: 1,
        })
        .to(
          ".openingAnime01",
          {
            top: "86%",
            left: "5%",
          },
          "<"
        )
        .to(
          ".openingAnime02",
          {
            top: "5%",
            left: "10%",
          },
          "<"
        )
        .to(
          ".openingAnime03",
          {
            top: "40%",
            left: "95%",
          },
          "<"
        )
        .to(
          ".openingAnime04",
          {
            top: "90%",
            left: "12%",
          },
          "<"
        )
        .to(
          ".openingAnime05",
          {
            top: "80%",
            left: "40%",
          },
          "<"
        )
        .to(
          ".openingAnime06",
          {
            top: "15%",
            left: "80%",
          },
          "<"
        )
        .to(
          ".openingAnime07",
          {
            top: "70%",
            left: "75%",
          },
          "<"
        )
        .to(
          ".openingAnime08",
          {
            top: "-1%",
            left: "-1%",
          },
          "<"
        )
        .to(
          ".openingAnime09",
          {
            top: "90%",
            left: "25%",
          },
          "<"
        )
        .to(
          ".openingAnime10",
          {
            top: "-1%",
            left: "55%",
          },
          "<"
        )
        .to(
          ".openingAnime11",
          {
            top: "70%",
            left: "95%",
          },
          "<"
        )
        .to(
          ".openingAnime12",
          {
            top: "85%",
            left: "55%",
          },
          "<"
        )
        .set("#header.top", {
          opacity: 0,
          y: "-100%",
        })
        .to(
          ".openingAnime13",
          {
            top: "65%",
            left: "-2%",
            onComplete: this.hero,
          },
          "<"
        )
        .to(".opening", {
          scale: 1,
          duration: 3,
          ease: "power2.out",
        })
        .to(
          "#header.top",
          {
            opacity: 0,
            y: "-100%",
            duration: 0,
          },
          "<"
        )
        .to("#header.top", {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: "power4.out",
        });
    },
    hero() {
      this.isOpened = true;
      console.log("hero");
      const tl = gsap.timeline({
        repeat: 0,
        defaults: {
          delay: 0.1,
          duration: 0.3,
          ease: "circ.out",
        },
      });
      tl.set(".bg01", {
        opacity: 0,
      })
        .set(".bg03", {
          y: 30,
        })
        .set(".bg02", {
          x: -20,
        })
        .set(".catch", {
          x: 20,
        })
        // .set(".bg04", {
        //   opacity: 0,
        //   scale: 0.9,
        // })
        .set(".messageLG", {
          scale: 0.3,
          rotate: 600,
        })
        .to(".bg03", {
          opacity: 1,
          y: 0,
          duration: 0.6,
          ease: "power1.out",
        })
        .to(".bg01", {
          opacity: 1,
          duration: 0.6,
          ease: "power1.out",
        })
        .to(
          ".bg02",
          {
            x: 0,
            duration: 0.6,
            ease: "power1.out",
          },
          "<"
        )
        // .to(".bg04", {
        //   opacity: 1,
        //   scale: 1,
        //   duration: 1,
        //   ease: "bounce.out",
        // })
        .to(
          ".catch",
          {
            opacity: 1,
            x: 0,
            duration: 2,
            ease: "power1.out",
          },
          "<"
        )
        .to(
          ".messageLG",
          {
            opacity: 1,
            scale: 1,
            rotate: 0,
            duration: 1,
            ease: "power4.out",
          },
          "<"
        );
    },
    InView() {
      const trigger = document.querySelectorAll("[data-trigger]");
      trigger.forEach(function (userItem) {
        ScrollTrigger.create({
          trigger: userItem,
          start: "top bottom-=50px",
          end: "bottom top+=50px",
          toggleClass: { targets: userItem, className: "InView" },
          once: true,
        });
      });
    },
  },
});
